<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { TribesMetaData, findLabel } from "@/data/tribes.js";
import {
  uploadCoverImageService,
  uploadCoverVideoService,
  uploadImageService,
  updateTribe,
} from "@/services/api/tribe";

/**
 * Tribe wizard component
 */
export default {
  page: {
    title: "Form Wizard",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, FormWizard, TabContent },
  params: ["tribeItems"],
  data() {
    return {
      tribeItems: {},
      tribeData: {},
      tribeImages: [],
      adding: false,
      title: "Tribe Wizard",
      findLabel: findLabel,

      items: [
        {
          text: "Tribes",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],
      values: {},
      step1: [
        {
          component: "div",
          class: "row",
          children: [
            {
              type: "text",
              name: "name",
              label: "Tribe Name",
              class: "col-sm-6 col-12",
              validation: "required",
              "error-behavior": "live",
            },
            {
              type: "text",
              name: "title",
              label: "Tribe Title",
              class: "col-sm-6 col-12",
              validation: "required",
              "error-behavior": "live",
            },
          ],
        },
        {
          type: "textarea",
          name: "primary_description",
          label: "Description",
        },
        {
          component: "div",
          class: "row",
          children: [
            {
              type: "number",
              name: "no_of_days",
              label: "Number of Days",
              class: "col-sm-6 col-12",
            },
            {
              type: "number",
              name: "rating",
              label: "Rating",
              class: "col-sm-6 col-12",
            },
          ],
        },
        {
          component: "div",
          class: "row",
          children: [
            {
              type: "select",
              name: "region",
              options: TribesMetaData.region,
              label: "Region",
              class: "col-sm-6 col-12",
              placeholder: "Select a region",
            },
            {
              type: "select",
              name: "climate",
              options: TribesMetaData.climate,
              label: "Climate",
              class: "col-sm-6 col-12",
              placeholder: "Select a climate",
            },
            {
              type: "select",
              name: "challenge",
              options: TribesMetaData.challenge,
              label: "Challenge",
              class: "col-sm-6 col-12",
              placeholder: "Select a challenge",
            },
          ],
        },
        {
          component: "div",
          class: "row mt-3",
          children: [
            {
              type: "image",
              name: "cover_image",
              label: "Cover Image",
              class: "col-sm-6 col-12",
              validation: "mime:image/jpeg,image/png,image/gif",
              uploader: this.uploadCoverImage,
              "@file-removed": this.removeFile,
              disabled: true,
              help: "You can only upload image after filling the Tribe name !",
            },

            {
              type: "file",
              name: "cover_video",
              label: "Cover Video",
              src: this.rou,
              class: "col-sm-6 col-12",
              validation: "mime:video/mp4",
              uploader: this.uploadCoverVideo,
              "@file-removed": this.removeFile,
              disabled: true,
              help: "You can only upload video after filling the Tribe name !",
            },
          ],
        },
      ],
      step2: [
        {
          component: "div",
          class: "row mt-3",
          children: [
            {
              type: "textarea",
              name: "home",
              label: "Home",
              class: "col-sm-6 col-12",
            },
            {
              type: "textarea",
              name: "life",
              label: "Life",
              class: "col-sm-6 col-12",
            },
          ],
        },
        {
          component: "div",
          class: "row mt-3",
          children: [
            {
              type: "textarea",
              name: "religion",
              label: "Religion",
              class: "col-sm-6 col-12",
            },
            {
              type: "textarea",
              name: "social_structure",
              label: "Social Structure",
              class: "col-sm-6 col-12",
            },
          ],
        },
        {
          component: "div",
          class: "row mt-3",
          children: [
            {
              type: "textarea",
              name: "language",
              label: "Language",
              class: "col-sm-6 col-12",
            },
            {
              tribeData: {},
              type: "textarea",
              name: "traditions",
              label: "Traditions",
              class: "col-sm-6 col-12",
            },
          ],
        },
        {
          component: "div",
          class: "row mt-3",
          children: [
            {
              type: "textarea",
              name: "celebrations",
              label: "Celebrations",
              class: "col-sm-6 col-12",
            },
            {
              type: "textarea",
              name: "art",
              label: "Art",
              class: "col-sm-6 col-12",
            },
          ],
        },

        {
          type: "textarea",
          name: "further_knowledge",
          label: "Further Knowledge",
        },
      ],
      images: [
        {
          type: "group",
          repeatable: true,
          name: "images",
          label: "Images",
          addLabel: "+ Image",
          class: "tribe-images-upload-container",
          children: [
            {
              type: "image",
              class: "col-12",
              validation: "mime:image/jpeg,image/png,image/gif",
              "@file-removed": this.removeFile,
              uploader: this.uploadImage,
            },
          ],
        },
      ],
      transport: [
        {
          type: "group",
          repeatable: true,
          name: "transport",
          label: "Transport",
          addLabel: "+ Transport",
          class: "tribe-images-upload-container",
          children: [
            {
              component: "div",
              class: "row",
              children: [
                {
                  type: "text",
                  label: "Type",
                  name: "type",
                  class: "col-sm-4 col-12",
                },
                {
                  type: "text",
                  class: "col-sm-4 col-12",
                  label: "Title",
                  name: "title",
                },
                {
                  type: "text",
                  class: "col-sm-4 col-12",
                  label: "Text",
                  name: "text",
                },
              ],
            },
          ],
        },
      ],
      marker: { position: { lat: 0, lng: 0 } },
      center: { lat: 0, lng: 0 },
    };
  },
  watch: {
    values(val) {
      if (val.name && val.name.replace(/ /g, "").length) {
        for (let i = 0; i < this.step1[4].children.length; i++) {
          this.step1[4].children[i].disabled = false;
        }
      } else {
        for (let i = 0; i < this.step1[4].children.length; i++) {
          this.step1[4].children[i].disabled = true;
        }
      }
    },
  },
  mounted() {
    this.fetchTribe();
  },
  methods: {
    deleteTribeImages(id) {
      this.tribeData.item.images.splice(id, 1);
    },
    fetchTribe() {
      this.tribeData = this.$route.params.tribeItems;
      this.values = this.tribeData.item;
      this.tribeImages = this.tribeData.item.images;
    },
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
      this.$refs.mapRef.setZoom(18);
    },
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      console.log(e);
    },
    async uploadCoverImage(img, progress) {
      const form = new FormData();
      form.append("file", img);
      form.append("fileType", img.type.split("/")[1]);
      const result = await uploadCoverImageService(form, progress).then(
        (res) => {
          console.log(res.data.data);
          return res.data.data;
        }
      );
      return await result;
    },
    async uploadCoverVideo(video, progress) {
      const form = new FormData();
      form.append("file", video);
      form.append("fileType", video.type.split("/")[1]);
      form.append("name", "AbuTheTribe");
      const result = await uploadCoverVideoService(form, progress).then(
        (res) => {
          return res.data;
        }
      );
      return await result;
    },
    async uploadImage(img, progress) {
      const form = new FormData();
      form.append("file", img);
      form.append("fileType", img.type.split("/")[1]);
      form.append("name", "AbuTheTribe");
      var res = await uploadImageService(form, progress);
      console.log("DG6");
      console.log(res.data.data);

      this.tribeImages.push(res.data.data);
    },
    submitTribeForm(tribeId) {
      this.adding = true;
      var data = this.values;
      data.images = this.tribeImages;
      data.tribeId = tribeId;
      data.cover_image =
        (this.values.cover_image &&
          this.values.cover_image.results &&
          this.values.cover_image.results[0].data) ||
        this.tribeData.item.cover_image;
      console.log("data", this.values);
      updateTribe(this.values)
        .then((res) => {
          console.log(res.data);
          this.tribesList();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.adding = false;
        });
    },
    tribesList() {
      this.$bvModal.msgBoxConfirm("Record has been updated").then(() => {
        this.$router.push({ path: "/tribes" });
      });
    },
    removeFile() {
      //If there is an api for removing file use that
      console.log("File removed!");
    },
    validateStep1() {
      return this.$refs["firstStep"].isValid;
    },
    tabChanged() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="card">
      <div class="card-body">
        <form-wizard
          color="#556ee6"
          finishButtonText="Submit"
          @on-complete="submitTribeForm(tribeData.item._id)"
          @on-change="tabChanged"
        >
          <tab-content
            icon="mdi mdi-account-edit-outline"
            :before-change="validateStep1"
          >
            <FormulateForm
              ref="firstStep"
              name="firstStep"
              v-model="values"
              :schema="step1"
            />

            <img
              style="position: absolute; left: 130px; top: 605px"
              :src="tribeData.item.cover_image"
              width="80"
              alt=""
              srcset=""
              class="rounded"
            />
            <img
              style="position: absolute; left: 920px; top: 605px"
              :src="tribeData.item.cover_video"
              width="80"
              alt=""
              srcset=""
              class="rounded"
            />
            <GmapMap
              :center="center"
              :zoom="2"
              style="height: 240px"
              @click="handleMapClick"
            >
              <GmapMarker
                :position="marker.position"
                :clickable="true"
                :draggable="true"
                @drag="handleMarkerDrag"
                @click="panToMarker"
              />
            </GmapMap>
            <div class="row">
              <div class="col-sm-6 col-12">
                <FormulateInput
                  type="number"
                  v-model="marker.position.lng"
                  label="Longitude"
                />
              </div>
              <div class="col-sm-6 col-12">
                <FormulateInput
                  type="number"
                  v-model="marker.position.lat"
                  label="Latitude"
                />
              </div>
            </div>
          </tab-content>
          <tab-content icon="mdi mdi-account-details-outline">
            <FormulateForm v-model="values" :schema="step2" />
            <table style="margin-left: 75px">
              <tbody>
                <tr v-for="(image, index) in tribeImages" :key="index">
                  <td><img :src="image" height="40" width="50" /></td>
                  <td>
                    <a
                      href="javascript:void(0);"
                      class="text-danger"
                      v-b-tooltip.hover
                      title="Delete"
                      @click="deleteTribeImages(id)"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <FormulateForm v-model="tribeData.item.images" :schema="images" />
            <FormulateForm v-model="values" :schema="transport" />
          </tab-content>
          <tab-content icon="mdi mdi-checkbox-marked-circle-outline">
            <div class="row">
              <div class="col-12">
                <div class="text-center">
                  <h3 class="mt-0">{{ values.name }}</h3>
                  <h6>{{ values.title }}</h6>
                  <div class="preview-container">
                    <p
                      class="w-75 mb-2 mx-auto"
                      v-if="values.primary_description"
                    >
                      {{ values.primary_description }}
                    </p>
                    <p class="w-75 mb-2 mx-auto" v-else>No Description</p>
                  </div>
                  <h6 style="margin-top: 42px; margin-bottom: 84px">
                    Are you sure do you want to add the tribe
                    {{ values.name }} to the database?
                  </h6>
                  <b-spinner
                    type="grow"
                    label="Spinning"
                    v-if="adding"
                  ></b-spinner>
                </div>
              </div>
            </div>
          </tab-content>
        </form-wizard>
      </div>
    </div>
  </Layout>
</template>
<style>
.images-min-width {
  width: 180px;
}
.preview-container {
  background-color: #fafafa;
  border-radius: 12px;
  padding: 18px 12px;
}
</style>


